
import { InspectionEntityModel, InspectionQueryModel } from '@common-src/entity-model/inspection-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/inspection`;

class InspectionService implements ICRUDQ<InspectionEntityModel, InspectionQueryModel> {
    async create(model: InspectionEntityModel): Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new InspectionEntityModel().toModel(res);
    }

    async update(model: InspectionEntityModel): Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: InspectionEntityModel): Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: InspectionQueryModel, page?: number, limit?: number): Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new InspectionEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new InspectionEntityModel().toModel(item));
    }

    async sync(): Promise<any> {
        const url = `${URL_PATH}/syncJob`;
        const res = await post(url);
        return res;
    }

    async export(params): Promise<any> {
        const url = `${URL_PATH}/export`;
        const res = await download(url, params, 'POST');
        return res;
    }
}

export default new InspectionService();
